/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { Layout } from '@core';
import { ForgotPassword } from 'src/components/composite/chart-generator/auth';
import { styles } from './_index.styles';

export default function () {
  return (
    <Layout>
      <Box sx={styles.container}>
        <ForgotPassword />
      </Box>
    </Layout>
  );
}
